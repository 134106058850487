import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { mobile, tablet } from '~styles/global'
import FadeTransition from '~components/FadeTransition'

const TextSlider = ({ className, text }) => {

	const [current, setCurrent] = useState(0)

	useEffect(() => {
		window.setTimeout(() => {
			setCurrent(current + 1 === text.length ? 0 : current + 1)
		}, 5500)
	}, [current])

	return (
		<Wrap className={className}>
			<FadeTransition integer={current} duration={1}>
				<Line className="h1">
					{text[current]}
				</Line>
			</FadeTransition>
		</Wrap>
	)
}

const Wrap = styled.div`
	height: 260px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-bottom: 1px solid var(--black);
	padding: 0 var(--m);
`

const Line = styled.h2`
	max-width: 1050px;
	text-align: center;
	${tablet}{
		max-width: 700px;
	}
`

TextSlider.propTypes = {
	className: PropTypes.string,
	text: PropTypes.array,
}

export default TextSlider